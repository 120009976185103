.infobox{
    display: flex;
    justify-content: space-between;
    height: 300px;
}
.info{
    width: 100%;
    font-size: 1.2rem;
}
.info ul{
    padding: 0px;
    margin: 0px 20px;
}

.info ul li {
    list-style: none;
    padding-left: 0px;
    line-height: 3;
}

.info ul li span:nth-child(2n) {
    margin-left: 40px;
}
.info span{
    line-height: 3;
    vertical-align: middle;
}
.info span svg{
    line-height: 3;
    vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .infobox {
    display: block;
  }
  .info{
    font-size: 16px;
  }
  .info ul{
    margin: 0px;
  }
  iframe{
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
}