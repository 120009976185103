.privatetable{
    width: 100%;
}
.privatetable ul{
    padding-left: 15%;
}
.privatetable tr{
    vertical-align: middle;
    /* display: block; */
    /* width: 100%; */
}
.egyebinfok tr td {
    width: 100%;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
}

.egyebinfok tr td:nth-child(2n+1) {
    display: flex;
    align-items: center;
    text-align: left;
}

li{
    list-style-type: circle;
    text-align: left;
}
.privatetable li::before {content: "•"; color: #708E80;
  display: inline-block; width: 1em;
  margin-left: -1em}
  
.toggleButtons{
    display: inline-flex;
    width: 100%;
    margin: 0 auto;
}
.toggleButtons button {
    width: 50%;
    line-height: 3;
    height: 80px;
    font-size: 18px;
    border: 1px solid rgba(0,0,0,.1);
    margin: 0px;
    color: rgba(0,0,0,.7);
}

.colored, button:hover, button:focus{
    background: rgb(170, 201, 178, .3);
}


@media (max-width: 768px) {
    .toggleButtons{
        display: flex;
    }
    .toggleButtons button{
       line-height: 1.4;
    }
}