.subheader{
    background:#AAC9B2;
    width:100%;
    text-align: center;
    font-size:18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    padding:20px;
    text-shadow: 1px 1px 1px rgba(0,0,0,.4);
    margin-top:0px;
    margin-bottom:0px;
    opacity: .7;
}
.subheader h1{
    font-size: 1rem;
    text-transform: none;
    letter-spacing: 1px;
    font-style: italic;
    opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .subheader h1 {
    font-size: .7rem;
  }
}