.rendeles {
    margin: 0px auto;
    border: 1px solid rgba(0,0,0,0.1);
    -webkit-box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
    -moz-box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.1);
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}
.rendelesiRend{
    width: 80%;
    margin: 2em auto;
    text-align: left;
}
.rendelesiRend div{
    padding-bottom: 2em
}
.rendelesiRend p{
    width: 100%;
    text-align: left;
}
.form{
    padding-left:2em;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
    
.header{
    background:#AAC9B2;
    text-align: center;
    font-size:18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    padding:20px;
    text-shadow: 1px 1px 1px rgba(0,0,0,.4);
}
.header h1{
    font-size: 1rem;
    text-transform: none;
    letter-spacing: 1px;
    font-style: italic;
    opacity: 0.5;
}

.timetable {
    width: 100%;
    margin: 0px auto 50px auto;
    opacity: .7;;
    font-weight: 400;
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}


tr {
    display:flex;
    justify-content: space-between;
    line-height: 3;
    margin: 0px 20%;
}

   /* table tr td input {
        line-height: 2;
        font-size: 20px;
        text-align: left;
        border:none;
        margin-left:65px;
    } */
    hr {
        height: 8px;
        border: none;
        background: #AAC9B2;
        margin: 0px 0px;
        opacity: .7;
    }
    p{line-height: 3;}

        .date {
        display: flex;
        justify-content: space-between;
        margin: 0px 20%;
    }
    
    h2, h3, p{
        text-align: center;
    }

    .merry{
        text-align: center;
        font-size: 1.3em;
        font-weight:bold;
        line-height: 1.8;
        margin: 20px auto;
    }
    .center {
        text-align: center;
        line-height: 1.5;
    }
    .winterdate{
        display: flex;
        p:nth-child(2) {
            text-align: right;
        }
    }
    .wintertable {
    width: 100%;
    margin: 0px auto 50px auto;
    opacity: .7;;
    font-weight: 400;
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}


.wintertable tr {
    line-height: 3;
}
.wintertable td {
    width: 33%;
}
 @keyframes borderAnimation {
      0% {
        border-color: #d4af37; /* Start with goldish color */
      }
      25% {
        border-color: #b8860b; /* Dark golden rod */
      }
      50% {
        border-color: #ffd700; /* Gold */
      }
      75% {
        border-color: #daa520; /* Golden rod */
      }
      100% {
        border-color: #d4af37; /* Back to goldish color */
      }
    } 

     .christmasMessage {
 font-family: 'Georgia', serif;
      font-size: 1.5em;
      color: #b22222; /* Firebrick red for a more elegant red */
      text-align: center;
      padding: 20px;
      background-color: #f9f9f9;
      border: 2px solid #d4af37; /* Goldish border */
      border-radius: 15px;
      margin: 30px auto;
      width: 70%;
      max-width: 600px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
       animation: borderAnimation 5s infinite;
    }

    .christmasMessage h1 {
      font-size: 1.4em;
      margin: 0;
      color: #008000; /* Christmas green */
    }

    .christmasIcon {
      font-size: 1.4em;
      color: #ff0000; /* Same red as the border */
      margin-right: 10px;
      vertical-align: middle;
    }
@media screen and (max-width: 768px) {
  .timetable {
    font-size: .9em;
  }
  tr {
    margin: 0px 10px;
}
}