.image{
    float:left;
    margin-right: 2vmin;
    margin-bottom: 2vmin;
    margin-top: 8vmin;
    width: 40vmin;
}

.shortIntro {
    padding: 0vmin 2vmin 3vmin 2vmin;
    overflow: auto;
}
.shortIntro p {
        text-align: justify;
    }

.long{
    padding: 0px 2vmin;
    line-height: 1.5;
    text-align: justify;
}
img{
    padding: 2vmin 2vmin;
}

@media (max-width: 768px) {
  .image {
    float: none;
    display: block;
    margin: 0 auto 2vmin auto; /* Center the image */
    width: 40%; /* Adjust width to fit smaller screens */
  }

  .shortIntro {
    text-align: center;
  }

  .shortIntro p {
    text-align: left; /* Left align text on smaller screens */
  }
}