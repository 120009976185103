@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');
* {
  box-sizing: border-box;
}

html {
  font-family: 'Lato', sans-serif;
}

body {
  margin: 0;
  background-color: white;
  color: rgba(0,0,0,.8);
}

