.footer {
    margin-top: 200px;
    display: grid;
}
.footer ul{
    display: inline-flex;
    line-height: 15vmin;
    justify-content: space-around;
    background-color: #708E80;
    vertical-align: middle;
}

.footer ul li{
    list-style: none;
}

.footer ul li span svg{
    line-height: 15vmin;
    vertical-align: middle;
    margin-right:2vmin;
}

@media screen and (max-width: 768px) {
  .footer ul {
    display: flex;
    flex-direction: column;
  }
}