.holiday{
    width: 100%;
    margin: 0px auto;
    padding: 20px;
    max-width: 80%;
}
.timetable {
    width: 60%;
    margin: 0px auto 50px auto;
    opacity: .7;;
    font-weight: 400;
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}

.timetable tr {
    border-bottom: 4px solid #AAC9B2;
}

.holiday p{
    line-height: 2;
}

.holiday .signature {
    display: flex;
  justify-content: space-between;
  align-items: center;
}