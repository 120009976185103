.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    background-color: #AAC9B2;
    padding: 0 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.main-image {
    width: 100%;
    height: 45rem;
    z-index: 0;
    overflow: hidden;
    /* opacity: 1; */
    
      /* box-shadow: 0 0 8px rgb(0 0 0 / 30%); */
    /* background: url(../../assets/background.jpg) no-repeat center center fixed;
    background-size: cover; */
    /* background-image: url(../../assets/background.jpg);
    background-position: center center;
    -moz-background-position: center center;
    -webkit-background-position: center center;
    -o-background-position: center center;
    -ms-background-position: center center;
     -moz-background-size: 100% auto; /* Gecko 1.9.2 (Firefox 3.6) */
   /* -o-background-size: 100% auto; /* Opera 9.5 */
   /* -webkit-background-size: 100% auto; /* Safari 3.0 */
   /* -ms-background-size: 100% auto; /* Safari 3.0 */
    /*background-repeat: no-repeat;
    background-attachment: fixed; */
}
.fadeOnScroll{
    /* animation-name: fade;
    animation-duration:1s; */
    /* opacity: 0; */
    /* z-index: -10; */
}

.main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    position: fixed;
    height: 40rem;
    /* z-index: -10; */

    /* transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem); */
    top:6rem;

}

/* @keyframes maintitle {
    0% {font-size:0.5rem;}
    100% {font-size:3rem;}
} */
@keyframes maintitle {
    0% {transform: scale(0.5,0.5);}
    100% {transform: scale(1,1)};
}
.mainTitle{
    position: fixed;
    /* z-index: -5; */
    top:7rem;
    text-align: center;
    /* background: rgb(155,191,163,0.5); */
    width: 100%;
    /* opacity: .7; */
    font-size: 3.5rem;
    letter-spacing: .5rem;
    color: rgb(251, 253, 97);
    animation-name: maintitle;
    animation-duration: 2s;
    text-shadow: 0px 4px 3px rgb(0 0 0 / 40%), 0px 8px 13px rgb(0 0 0 / 10%), 0px 18px 23px rgb(0 0 0 / 30%);
}


@media screen and (max-width: 768px) {
    .main-image{
        height: 30rem;
    }
  .main-image img {
top: -5rem;
  }
}