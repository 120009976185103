@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Lato', sans-serif;
}

body {
  margin: 0;
  background-color: white;
  color: rgba(0,0,0,.8);
}


.Header_header__1ewZk {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    background-color: #AAC9B2;
    padding: 0 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.Header_main-image__3F35C {
    width: 100%;
    height: 45rem;
    z-index: 0;
    overflow: hidden;
    /* opacity: 1; */
    
      /* box-shadow: 0 0 8px rgb(0 0 0 / 30%); */
    /* background: url(../../assets/background.jpg) no-repeat center center fixed;
    background-size: cover; */
    /* background-image: url(../../assets/background.jpg);
    background-position: center center;
    -moz-background-position: center center;
    -webkit-background-position: center center;
    -o-background-position: center center;
    -ms-background-position: center center;
     -moz-background-size: 100% auto; /* Gecko 1.9.2 (Firefox 3.6) */
   /* -o-background-size: 100% auto; /* Opera 9.5 */
   /* -webkit-background-size: 100% auto; /* Safari 3.0 */
   /* -ms-background-size: 100% auto; /* Safari 3.0 */
    /*background-repeat: no-repeat;
    background-attachment: fixed; */
}
.Header_fadeOnScroll__36trq{
    /* animation-name: fade;
    animation-duration:1s; */
    /* opacity: 0; */
    /* z-index: -10; */
}

.Header_main-image__3F35C img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    position: fixed;
    height: 40rem;
    /* z-index: -10; */

    /* transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem); */
    top:6rem;

}

/* @keyframes maintitle {
    0% {font-size:0.5rem;}
    100% {font-size:3rem;}
} */
@keyframes Header_maintitle__1tzn- {
    0% {transform: scale(0.5,0.5);}
    100% {transform: scale(1,1)};
}
.Header_mainTitle__1iEYS{
    position: fixed;
    /* z-index: -5; */
    top:7rem;
    text-align: center;
    /* background: rgb(155,191,163,0.5); */
    width: 100%;
    /* opacity: .7; */
    font-size: 3.5rem;
    letter-spacing: .5rem;
    color: rgb(251, 253, 97);
    animation-name: Header_maintitle__1tzn-;
    animation-duration: 2s;
    text-shadow: 0px 4px 3px rgb(0 0 0 / 40%), 0px 8px 13px rgb(0 0 0 / 10%), 0px 18px 23px rgb(0 0 0 / 30%);
}


@media screen and (max-width: 768px) {
    .Header_main-image__3F35C{
        height: 30rem;
    }
  .Header_main-image__3F35C img {
top: -5rem;
  }
}
.Navigation_navigation__2SNdT {
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fff; */
  /* color: white; */
  /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
}

.Navigation_brand-name__2ao8v {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.Navigation_brand-name__2ao8v img {
    width: 20vmin;
    height: auto;
    margin-top:10vmin;
}
.Navigation_navigation-menu__15m4J {
  height: 100%;
  width: 100%;
  margin-left: auto;
}


.Navigation_navigation-menu__15m4J ul {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  height: 100%;
  align-items: center;
  /* display: flex;
  padding: 0;
  align-items: center; */
}
.Navigation_navigation-menu__15m4J li {
  list-style-type: none;
}
.Navigation_navigation-menu__15m4J li a {
  text-decoration: none;
  display: block;
  /* width: 100%; */
  color:rgba(0,0,0,.7);
  font-size: 1.1em;
  transition: all .2s;
    padding: 1vmin 1vmin 1vmin 1vmin;
      /* padding:2rem 1rem; */
  outline:rgb(127, 173, 139) solid 0px;
  border-radius: 2px;
}
.Navigation_navigation-menu__15m4J li a:hover{
  outline:rgb(127, 173, 139) solid 2px;
  color: rgba(0,0,0,.5);
}

    /* width: 100%;
    background: #AAC9B2;
    text-align: right;
    box-sizing: border-box;
    padding: 14px 24px;
    cursor: pointer;
    color: #fff; */
.Navigation_hamburger__3MWlm {
  border: 0;
  outline:rgb(127, 173, 139) solid 0px;
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;
  border-radius: 10%;
  background-color: #AAC9B2;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: all 0.4s ease-out;
  display: none;
}
.Navigation_hamburger__3MWlm:hover {
  outline:rgb(127, 173, 139) solid 2px;
}

@media screen and (max-width: 768px) {
  .Navigation_hamburger__3MWlm {
    display: block;
  }

  .Navigation_navigation-menu__15m4J {
    display:none;
  }
}

@media screen and (max-width: 768px) {
  .Navigation_navigation-menu__15m4J{
    margin-left: 0%; 
  }
  .Navigation_navigation-menu__15m4J ul {
    display: block;
    position: absolute;
    top: 60px;
    /* left: 0px; */
    flex-direction: column;
    width: 60%;
    /* height: calc(100vh - 77px); */

    opacity: 1;
    /* transform: translateX(50%); */
    border-top: 1px solid #AAC9B2;
  }
  .Navigation_navigation-menu__15m4J li {
    text-align: center;
    margin: 0 auto;
    background-color: #AAC9B2;
    width: 100%;
  }
  .Navigation_navigation-menu__15m4J li a {
    color: rgba(0,0,0,.7);
    width: 100%;
    padding: 1rem 0;
    font-size: 18px;
    transition: all 1s ease-out;
  }
  .Navigation_navigation-menu__15m4J li a:hover{
  font-size: 18px;
  background-color:#708E80;
}
.Navigation_brand-name__2ao8v img {
    width: 24vmin;
    height: auto;
    margin-top:20vmin;
    margin-left: 0vmin;
}
}
@media screen and (max-width: 768px) {
  .Navigation_expanded__1c2MV {
    display: block;
    z-index: 0;
  }
  .Navigation_brand-name__2ao8v img {
    width: 24vmin;
    height: auto;
    margin-top:24vmin;
    margin-left: -10vmin;
}
}
.SubHeader_subheader__1ZzNf{
    background:#AAC9B2;
    width:100%;
    text-align: center;
    font-size:18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    padding:20px;
    text-shadow: 1px 1px 1px rgba(0,0,0,.4);
    margin-top:0px;
    margin-bottom:0px;
    opacity: .7;
}
.SubHeader_subheader__1ZzNf h1{
    font-size: 1rem;
    text-transform: none;
    letter-spacing: 1px;
    font-style: italic;
    opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .SubHeader_subheader__1ZzNf h1 {
    font-size: .7rem;
  }
}
.Timetable_rendeles__OYzOx {
    margin: 0px auto;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}
.Timetable_rendelesiRend__lccov{
    width: 80%;
    margin: 2em auto;
    text-align: left;
}
.Timetable_rendelesiRend__lccov div{
    padding-bottom: 2em
}
.Timetable_rendelesiRend__lccov p{
    width: 100%;
    text-align: left;
}
.Timetable_form__2qwsS{
    padding-left:2em;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}
    
.Timetable_header__2qyf3{
    background:#AAC9B2;
    text-align: center;
    font-size:18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    padding:20px;
    text-shadow: 1px 1px 1px rgba(0,0,0,.4);
}
.Timetable_header__2qyf3 h1{
    font-size: 1rem;
    text-transform: none;
    letter-spacing: 1px;
    font-style: italic;
    opacity: 0.5;
}

.Timetable_timetable__112to {
    width: 100%;
    margin: 0px auto 50px auto;
    opacity: .7;;
    font-weight: 400;
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}


tr {
    display:flex;
    justify-content: space-between;
    line-height: 3;
    margin: 0px 20%;
}

   /* table tr td input {
        line-height: 2;
        font-size: 20px;
        text-align: left;
        border:none;
        margin-left:65px;
    } */
    hr {
        height: 8px;
        border: none;
        background: #AAC9B2;
        margin: 0px 0px;
        opacity: .7;
    }
    p{line-height: 3;}

        .Timetable_date__25SEC {
        display: flex;
        justify-content: space-between;
        margin: 0px 20%;
    }
    
    h2, h3, p{
        text-align: center;
    }

    .Timetable_merry__29ae4{
        text-align: center;
        font-size: 1.3em;
        font-weight:bold;
        line-height: 1.8;
        margin: 20px auto;
    }
    .Timetable_center__1lZvb {
        text-align: center;
        line-height: 1.5;
    }
    .Timetable_winterdate__15pO7{
        display: flex;
        p:nth-child(2) {
            text-align: right;
        }
    }
    .Timetable_wintertable__L853u {
    width: 100%;
    margin: 0px auto 50px auto;
    opacity: .7;;
    font-weight: 400;
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}


.Timetable_wintertable__L853u tr {
    line-height: 3;
}
.Timetable_wintertable__L853u td {
    width: 33%;
}
 @keyframes Timetable_borderAnimation__DgSld {
      0% {
        border-color: #d4af37; /* Start with goldish color */
      }
      25% {
        border-color: #b8860b; /* Dark golden rod */
      }
      50% {
        border-color: #ffd700; /* Gold */
      }
      75% {
        border-color: #daa520; /* Golden rod */
      }
      100% {
        border-color: #d4af37; /* Back to goldish color */
      }
    } 

     .Timetable_christmasMessage__367m5 {
 font-family: 'Georgia', serif;
      font-size: 1.5em;
      color: #b22222; /* Firebrick red for a more elegant red */
      text-align: center;
      padding: 20px;
      background-color: #f9f9f9;
      border: 2px solid #d4af37; /* Goldish border */
      border-radius: 15px;
      margin: 30px auto;
      width: 70%;
      max-width: 600px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
       animation: Timetable_borderAnimation__DgSld 5s infinite;
    }

    .Timetable_christmasMessage__367m5 h1 {
      font-size: 1.4em;
      margin: 0;
      color: #008000; /* Christmas green */
    }

    .Timetable_christmasIcon__3cyhX {
      font-size: 1.4em;
      color: #ff0000; /* Same red as the border */
      margin-right: 10px;
      vertical-align: middle;
    }
@media screen and (max-width: 768px) {
  .Timetable_timetable__112to {
    font-size: .9em;
  }
  tr {
    margin: 0px 10px;
}
}
@keyframes Card_pageLoad__2cHaC {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.Card_animatedPageLoad__SOzd8 {
    animation-name: Card_pageLoad__2cHaC;
    animation-duration: 2s;
    animation-timing-function: ease-out;
}

.Card_container__1zPyw {
    width: 70%;
    margin: 6vmin auto;
    background: white;
    box-shadow: 10px 10px 5px -4px #AAC9B2;
    margin-bottom: 80px;
    position: relative;
    /* padding: 0vmin 2vmin; */
    /* box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%); */

}

button {
    background: transparent;
}

@media (max-width: 768px) {
    .Card_container__1zPyw{
        width: 100%;
        padding: 5px;
        margin: 18vmin auto;
    }
}
@media (max-width: 768px) {
    .Card_container__1zPyw{
        margin: 32vmin auto;
    }
}
.Footer_footer__3Z89s {
    margin-top: 200px;
    display: grid;
}
.Footer_footer__3Z89s ul{
    display: inline-flex;
    line-height: 15vmin;
    justify-content: space-around;
    background-color: #708E80;
    vertical-align: middle;
}

.Footer_footer__3Z89s ul li{
    list-style: none;
}

.Footer_footer__3Z89s ul li span svg{
    line-height: 15vmin;
    vertical-align: middle;
    margin-right:2vmin;
}

@media screen and (max-width: 768px) {
  .Footer_footer__3Z89s ul {
    display: flex;
    flex-direction: column;
  }
}
.Holiday_holiday__3GT7w{
    width: 100%;
    margin: 0px auto;
    padding: 20px;
    max-width: 80%;
}
.Holiday_timetable__3v0gD {
    width: 60%;
    margin: 0px auto 50px auto;
    opacity: .7;;
    font-weight: 400;
    box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%);
}

.Holiday_timetable__3v0gD tr {
    border-bottom: 4px solid #AAC9B2;
}

.Holiday_holiday__3GT7w p{
    line-height: 2;
}

.Holiday_holiday__3GT7w .Holiday_signature__nrhfx {
    display: flex;
  justify-content: space-between;
  align-items: center;
}
.Private_privatetable__2O171{
    width: 100%;
}
.Private_privatetable__2O171 ul{
    padding-left: 15%;
}
.Private_privatetable__2O171 tr{
    vertical-align: middle;
    /* display: block; */
    /* width: 100%; */
}
.Private_egyebinfok__3Uzym tr td {
    width: 100%;
    line-height: 1.5;
    vertical-align: middle;
    text-align: center;
}

.Private_egyebinfok__3Uzym tr td:nth-child(2n+1) {
    display: flex;
    align-items: center;
    text-align: left;
}

li{
    list-style-type: circle;
    text-align: left;
}
.Private_privatetable__2O171 li::before {content: "\2022"; color: #708E80;
  display: inline-block; width: 1em;
  margin-left: -1em}
  
.Private_toggleButtons__3_GWr{
    display: inline-flex;
    width: 100%;
    margin: 0 auto;
}
.Private_toggleButtons__3_GWr button {
    width: 50%;
    line-height: 3;
    height: 80px;
    font-size: 18px;
    border: 1px solid rgba(0,0,0,.1);
    margin: 0px;
    color: rgba(0,0,0,.7);
}

.Private_colored__2S7sv, button:hover, button:focus{
    background: rgb(170, 201, 178, .3);
}


@media (max-width: 768px) {
    .Private_toggleButtons__3_GWr{
        display: flex;
    }
    .Private_toggleButtons__3_GWr button{
       line-height: 1.4;
    }
}
.PersonalInfo_image__bud0Y{
    float:left;
    margin-right: 2vmin;
    margin-bottom: 2vmin;
    margin-top: 8vmin;
    width: 40vmin;
}

.PersonalInfo_shortIntro__1Z8MF {
    padding: 0vmin 2vmin 3vmin 2vmin;
    overflow: auto;
}
.PersonalInfo_shortIntro__1Z8MF p {
        text-align: justify;
    }

.PersonalInfo_long__aSHrJ{
    padding: 0px 2vmin;
    line-height: 1.5;
    text-align: justify;
}
img{
    padding: 2vmin 2vmin;
}

@media (max-width: 768px) {
  .PersonalInfo_image__bud0Y {
    float: none;
    display: block;
    margin: 0 auto 2vmin auto; /* Center the image */
    width: 40%; /* Adjust width to fit smaller screens */
  }

  .PersonalInfo_shortIntro__1Z8MF {
    text-align: center;
  }

  .PersonalInfo_shortIntro__1Z8MF p {
    text-align: left; /* Left align text on smaller screens */
  }
}


.Mind_headlines__3DKaI{
   display: flex;
   justify-content: space-around;
   vertical-align: middle;
   height: 60px;
   align-items: center;
  padding: 0vmin 2vmin;
}
.Mind_headtitle__3v9Nx {
    width: 60%;
    margin: 0 auto;
}

.Mind_mind__3vRzf .Mind_text__2h7MK{
    padding: 0px 2vmin;
}
.Mind_arrowUp__2Onew {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
    transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.Mind_arrowDown__YXyQ8 {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
button {
    border: 2px solid #AAC9B2;
    color: #AAC9B2;
    font-size: 4vmin;
    border-radius: 4px;
    margin-right: 5px;
    width: 5vmin;
    height: 5vmin;
}

@media screen and (max-width: 768px) {
.Mind_headlines__3DKaI{
     display: flex;
   justify-content: space-evenly;
   height: 18vh;
}
.Mind_headtitle__3v9Nx {
    width: 45%;
    margin: 0 auto;
}
button {
    width: 10vmin;
    height: 10vmin;
}
}
.Hello_hello__38MQQ {
    padding: 0px 2vmin 2vmin 2vmin;
}
.Hello_signed__1hq9M {
    text-align: right;
}
.Contact_infobox__3pD1p{
    display: flex;
    justify-content: space-between;
    height: 300px;
}
.Contact_info__27vjq{
    width: 100%;
    font-size: 1.2rem;
}
.Contact_info__27vjq ul{
    padding: 0px;
    margin: 0px 20px;
}

.Contact_info__27vjq ul li {
    list-style: none;
    padding-left: 0px;
    line-height: 3;
}

.Contact_info__27vjq ul li span:nth-child(2n) {
    margin-left: 40px;
}
.Contact_info__27vjq span{
    line-height: 3;
    vertical-align: middle;
}
.Contact_info__27vjq span svg{
    line-height: 3;
    vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .Contact_infobox__3pD1p {
    display: block;
  }
  .Contact_info__27vjq{
    font-size: 16px;
  }
  .Contact_info__27vjq ul{
    margin: 0px;
  }
  iframe{
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
}
