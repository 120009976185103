

.headlines{
   display: flex;
   justify-content: space-around;
   vertical-align: middle;
   height: 60px;
   align-items: center;
  padding: 0vmin 2vmin;
}
.headtitle {
    width: 60%;
    margin: 0 auto;
}

.mind .text{
    padding: 0px 2vmin;
}
.arrowUp {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
    transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.arrowDown {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
button {
    border: 2px solid #AAC9B2;
    color: #AAC9B2;
    font-size: 4vmin;
    border-radius: 4px;
    margin-right: 5px;
    width: 5vmin;
    height: 5vmin;
}

@media screen and (max-width: 768px) {
.headlines{
     display: flex;
   justify-content: space-evenly;
   height: 18vh;
}
.headtitle {
    width: 45%;
    margin: 0 auto;
}
button {
    width: 10vmin;
    height: 10vmin;
}
}