@keyframes pageLoad {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.animatedPageLoad {
    animation-name: pageLoad;
    animation-duration: 2s;
    animation-timing-function: ease-out;
}

.container {
    width: 70%;
    margin: 6vmin auto;
    background: white;
    box-shadow: 10px 10px 5px -4px #AAC9B2;
    margin-bottom: 80px;
    position: relative;
    /* padding: 0vmin 2vmin; */
    /* box-shadow: 10px 10px 5px -4px rgb(0 0 0 / 10%); */

}

button {
    background: transparent;
}

@media (max-width: 768px) {
    .container{
        width: 100%;
        padding: 5px;
        margin: 18vmin auto;
    }
}
@media (max-width: 768px) {
    .container{
        margin: 32vmin auto;
    }
}