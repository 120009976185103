.navigation {
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #fff; */
  /* color: white; */
  /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.brand-name img {
    width: 20vmin;
    height: auto;
    margin-top:10vmin;
}
.navigation-menu {
  height: 100%;
  width: 100%;
  margin-left: auto;
}


.navigation-menu ul {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  height: 100%;
  align-items: center;
  /* display: flex;
  padding: 0;
  align-items: center; */
}
.navigation-menu li {
  list-style-type: none;
}
.navigation-menu li a {
  text-decoration: none;
  display: block;
  /* width: 100%; */
  color:rgba(0,0,0,.7);
  font-size: 1.1em;
  transition: all .2s;
    padding: 1vmin 1vmin 1vmin 1vmin;
      /* padding:2rem 1rem; */
  outline:rgb(127, 173, 139) solid 0px;
  border-radius: 2px;
}
.navigation-menu li a:hover{
  outline:rgb(127, 173, 139) solid 2px;
  color: rgba(0,0,0,.5);
}

    /* width: 100%;
    background: #AAC9B2;
    text-align: right;
    box-sizing: border-box;
    padding: 14px 24px;
    cursor: pointer;
    color: #fff; */
.hamburger {
  border: 0;
  outline:rgb(127, 173, 139) solid 0px;
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;
  border-radius: 10%;
  background-color: #AAC9B2;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: all 0.4s ease-out;
  display: none;
}
.hamburger:hover {
  outline:rgb(127, 173, 139) solid 2px;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navigation-menu {
    display:none;
  }
}

@media screen and (max-width: 768px) {
  .navigation-menu{
    margin-left: 0%; 
  }
  .navigation-menu ul {
    display: block;
    position: absolute;
    top: 60px;
    /* left: 0px; */
    flex-direction: column;
    width: 60%;
    /* height: calc(100vh - 77px); */

    opacity: 1;
    /* transform: translateX(50%); */
    border-top: 1px solid #AAC9B2;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0 auto;
    background-color: #AAC9B2;
    width: 100%;
  }
  .navigation-menu li a {
    color: rgba(0,0,0,.7);
    width: 100%;
    padding: 1rem 0;
    font-size: 18px;
    transition: all 1s ease-out;
  }
  .navigation-menu li a:hover{
  font-size: 18px;
  background-color:#708E80;
}
.brand-name img {
    width: 24vmin;
    height: auto;
    margin-top:20vmin;
    margin-left: 0vmin;
}
}
@media screen and (max-width: 768px) {
  .expanded {
    display: block;
    z-index: 0;
  }
  .brand-name img {
    width: 24vmin;
    height: auto;
    margin-top:24vmin;
    margin-left: -10vmin;
}
}